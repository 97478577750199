/*!
    * Start Bootstrap - SB Admin Pro v1.2.0 (https://shop.startbootstrap.com/product/sb-admin-pro)
    * Copyright 2013-2020 Start Bootstrap
    * Licensed under SEE_LICENSE (https://github.com/StartBootstrap/sb-admin-pro/blob/master/LICENSE)
    */
   (function($) {
    "use strict";
  
    // Add active state to sidbar nav links
    var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
    $("#layoutSidenav_nav .sidenav a.nav-link").each(function() {
      if (this.href === path) {
        $(this).addClass("active");
      }
    });
  
    // Toggle the side navigation
    $("#sidebarToggle").on("click", function(e) {
      e.preventDefault();
      $("body").toggleClass("sidenav-toggled");
    });
  
    // Click to collapse responsive sidebar
    $("#layoutSidenav_content").click(function() {
      const BOOTSTRAP_LG_WIDTH = 992;
      if (window.innerWidth >= 992) {
        return;
      }
      if ($("body").hasClass("sidenav-toggled")) {
          $("body").toggleClass("sidenav-toggled");
      }
    });
  
    // Init sidebar
    let activatedPath = window.location.pathname.match(/([\w-]+\.html)/, '$1');
  
    if (activatedPath) {
      activatedPath = activatedPath[0];
    }
    else {
      activatedPath = 'index.html';
    }
      
    let targetAnchor = $('[href="' + activatedPath + '"]');
    let collapseAncestors = targetAnchor.parents('.collapse');
    
    targetAnchor.addClass('active');
    
    collapseAncestors.each(function() {
      $(this).addClass('show');
      $('[data-target="#' + this.id +  '"]').removeClass('collapsed');
       
    })
  
  })(jQuery);
  
  $(function () {
    $('input[name="profile[birthday]"]').daterangepicker(
        {
            singleDatePicker: true,
            showDropdowns: true,
            minYear: 1901,
            maxYear: parseInt(moment().format("YYYY"), 10),
        }
    );
});
